import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CsrfTokenService } from 'src/app/shared/services/csrf-token.service';
import { finalize } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  ConfigurableLoggerService,
  LoggerConfig,
} from 'src/app/shared/services/configurable-logger.service';

@Component({
  selector: 'app-root',
  template: `
    <div class="app-container">
      <!-- 代理ログインや会員ログインのステータスを表示するヘッダー。
           普段は表示しない
      -->
      <app-login-status-header
        *ngIf="!hideLoginStatusHeader"
      ></app-login-status-header>
      <!-- ヘッダーの表示制御を追加 -->

      <main [style.height]="getMainHeight()">
        <div class="navigation-header" *ngIf="!hideNavigationHeader">
          <div class="left-section">
            <!-- 戻るボタン -->
            <img
              src="assets/icons/left-arrow.svg"
              alt="back-button"
              class="back-button"
              (click)="onBackClick()"
            />
          </div>
          <h1 class="page-title">{{ pageTitle }}</h1>
          <div class="right-section">
            <ng-container *ngIf="userInfo$ | async as userInfo">
              <div
                class="user-info"
                [class.visible]="isUserInfoVisible"
                (click)="toggleUserInfo()"
              >
                <span class="display-name">{{ userInfo.display_name }}</span>
                <span class="member-id">{{ userInfo.member_id }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <router-outlet></router-outlet>
        <div class="iphone-padding-bottom-spacer"></div>
      </main>
      <app-mobile-footer-navigation></app-mobile-footer-navigation>
    </div>
  `,
  styles: [
    `
      @import 'src/styles/scss/variables';
      :host {
        --dummy-container-height: #{$dummy-container-height};
        --navigation-header-height: #{$navigation-header-height};
        --mobile-footer-height: #{$mobile-footer-height};

        .app-container {
          display: flex;
          flex-direction: column;
          height: 100vh; // hightを設定すると、.navigation-headerが固定されてしまう?
          // min-height: 100vh; // hightを設定すると、スクロールバーが二重に表示される。.navigation-headerが固定されてしまう?
          position: relative;

          .navigation-header {
            display: grid;
            grid-template-columns: $navigation-header-height 1fr $navigation-header-height;
            align-items: center;
            justify-items: center;
            height: $navigation-header-height;
            background-color: #ffebee;
            flex-shrink: 0; // 親コンテナのスペースが不足しても縮小しない
            // 子要素がヘッダーの高さを超えないように設定
            > * {
              max-height: $navigation-header-height;
            }

            .left-section {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            }

            .back-button {
              padding: 0.8rem;
              border: none;
              background: none;
              cursor: pointer;
            }

            .page-title {
              margin: 0;
              text-align: center;
              font-size: 20px;
              grid-column: 2;
              line-height: 48px;
            }

            .right-section {
              width: 48px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 8px;
            }

            // ================
            // ユーザ情報
            // ================
            .user-info {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              font-size: 0.8rem;
              color: white;
              transition: color 0.3s ease;
              cursor: pointer;
              .display-name {
                font-weight: bold;
              }

              .member-id {
                opacity: 0.7;
              }
            }

            .user-info.visible {
              color: #000;
            }
          }

          main {
            overflow-y: auto;
            overflow-x: hidden;
            background-color: skyblue;
            // background-color: orange;
            // background-color: purple;
            // background-color: $background-color-main;
          }

          app-mobile-footer-navigation {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: $mobile-footer-height;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0.5rem 0;
            background-color: #2d3748;
          }
        }
      }
    `,
  ],
  host: {
    '[class.login-status-header-hidden]': 'hideLoginStatusHeader',
    '[class.navigation-header-hidden]': 'hideNavigationHeader',
  },
})
export class AppComponent implements OnInit {
  title = environment.service_name;
  showTestOverlay: boolean = false;

  private logger: ConfigurableLoggerService;

  private activeComponent: any;
  pageTitle: string = '';
  userInfo$ = this.authService.loggedInUserInfo$;
  isUserInfoVisible = false;
  hideLoginStatusHeader: boolean = false;
  hideNavigationHeader: boolean = false;

  constructor(
    private location: Location,
    private authService: AuthService,
    private router: Router,
    private csrfTokenService: CsrfTokenService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    const loggerConfig: LoggerConfig = {
      enableDebug: environment.debug,
      enableInfo: true,
      enableWarn: true,
      enableError: true,
      prefix: 'MypageLayoutComponent',
      useTimestamp: true,
    };
    this.logger = new ConfigurableLoggerService(loggerConfig);
  }

  showOverlayTest() {
    this.showTestOverlay = !this.showTestOverlay;
  }
  ngOnInit() {
    this.logger.debug('MypageLayoutComponent ngOnInit');

    // 初期表示時のタイトル設定
    this.setTitleFromCurrentRoute();

    this.subscribeToNavigationEvents();
  }

  private setTitleFromCurrentRoute() {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    if (route.snapshot.data['title']) {
      this.logger.debug(
        '初期表示時にタイトルを設定:',
        route.snapshot.data['title']
      );
      this.pageTitle = route.snapshot.data['title'];
    }
  }

  // ナビゲーションイベントを監視するメソッド
  // ナビゲーション（ページ遷移）が発生するたびに実行
  private subscribeToNavigationEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getDeepestActiveRoute())
      )
      .subscribe((route) => {
        // スクロール位置をリセット
        const mainElement = document.querySelector('main');
        if (mainElement) {
          mainElement.scrollTop = 0;
        }

        this.updateTitleFromRoute(route);
        // デバッグログを追加
        this.logger.debug('Route data:', route.snapshot.data);
        this.logger.debug('hideLoginStatusHeader:', this.hideLoginStatusHeader);
        this.logger.debug('hideNavigationHeader:', this.hideNavigationHeader);

        this.hideLoginStatusHeader =
          route.snapshot.data['hideLoginStatusHeader'] || false;
        this.hideNavigationHeader =
          route.snapshot.data['hideNavigationHeader'] || false;

        // 設定後の値も確認
        this.logger.debug(
          'After setting - hideLoginStatusHeader:',
          this.hideLoginStatusHeader
        );

        this.logger.debug(
          'After setting - hideNavigationHeader:',
          this.hideNavigationHeader
        );
      });
  }

  // 最も深いアクティブなルートを取得するメソッド
  private getDeepestActiveRoute() {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  // ルートからタイトルを更新するメソッド
  private updateTitleFromRoute(route: ActivatedRoute) {
    if (route.snapshot.data['title']) {
      this.logger.debug(
        'ナビゲーション後にタイトルを設定:',
        route.snapshot.data['title']
      );
      this.pageTitle = route.snapshot.data['title'];
    }
  }

  onActivate(component: any) {
    this.logger.debug('アクティブ化されたコンポーネント:', component);
    this.activeComponent = component;

    // コンポーネントのpageTitleプロパティが存在し、かつルートデータにtitleが設定されていない場合のみ更新
    if (
      this.activeComponent &&
      'pageTitle' in this.activeComponent &&
      !this.activatedRoute.snapshot.firstChild?.data['title']
    ) {
      this.logger.debug(
        'コンポーネントからページタイトルを設定:',
        this.activeComponent.pageTitle
      );
      this.pageTitle = this.activeComponent.pageTitle;
    }
  }

  onBackClick() {
    if (this.activeComponent?.onBack) {
      this.activeComponent.onBack();
    } else {
      this.location.back();
    }
  }

  onLogout() {
    this.authService.logout(
      () => {
        console.log('onLogout : Logged out successfully.');
        this.authService
          .checkAuthenticationStatus()
          .pipe(
            finalize(() => {
              this.refreshCsrfTokenAndNavigate();
            })
          )
          .subscribe({
            next: () => console.log('Authentication status checked.'),
            error: (error) =>
              console.error('Error checking authentication status:', error),
          });
      },
      (error) => {
        console.error('Error while logging out:', error);
        alert('Logout failed. Please try again.');
      }
    );
  }

  private refreshCsrfTokenAndNavigate() {
    this.csrfTokenService.refreshCsrfToken().subscribe({
      next: () => {
        console.log('CSRF token refreshed successfully.');
        this.router.navigate(['/']);
      },
      error: (error) => {
        console.error('Error refreshing CSRF token:', error);
        this.router.navigate(['/']);
      },
    });
  }

  // ユーザ情報の表示/非表示を切り替えるメソッド
  toggleUserInfo() {
    this.isUserInfoVisible = !this.isUserInfoVisible;
  }

  updatePageTitle(title: string) {
    this.logger.debug('Updating page title to:', title);
    this.pageTitle = title;
    this.changeDetectorRef.detectChanges(); // 変更検出を実行
  }

  // ヘッダーとフッターを差し引いて、mainの高さを計算する関数
  getMainHeight(): string {
    const dummyHeight = this.hideLoginStatusHeader
      ? '0px'
      : 'var(--dummy-container-height)';
    const navigationHeaderHeight = this.hideNavigationHeader
      ? '0px'
      : 'var(--navigation-header-height)';
    return `calc(100vh + ${navigationHeaderHeight} - ${dummyHeight} - var(--mobile-footer-height))`;
  }
}
