import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-footer-navigation',
  template: `
    <a routerLink="/" class="nav-button home-button">
      <mat-icon>home</mat-icon>
    </a>
    <a routerLink="/discover" class="nav-button discover-button disabled-icon">
      <mat-icon>search</mat-icon>
    </a>
    <a routerLink="/mypage/upload" class="nav-button add-button">
      <mat-icon>add_circle_outline</mat-icon>
    </a>
    <a routerLink="/" class="nav-button inbox-button">
      <mat-icon class="disabled-icon">notifications</mat-icon>
    </a>
    <a routerLink="/mypage" class="nav-button profile-button">
      <mat-icon>person_outline</mat-icon>
    </a>
  `,
  styles: [
    `
      a {
        color: white;
      }
      svg {
        stroke: white;
      }
      .disabled-icon {
        color: #808080; /* または rgba(255, 255, 255, 0.5) でより柔らかいグレー */
        opacity: 0.5;
      }
    `,
  ],
})
export class MobileFooterNavigationComponent {}
